<template>
  <div id="content">
    
    <h1>
      Impressum
    </h1>

    <div>
      Diese Website wird von der
    </div>
    <div>
      forsa <br />
      Gesellschaft für Sozialforschung <br />
      und statistische Analysen mbH <br />
      Gutenbergstraße 2 <br />
      10587 Berlin <br />
      Telefon: 0800 3677201 <br />
      Fax: 030 62882400 <br />
      E-Mail: <a href="mailto:omninet@forsa.de"><span>omninet@forsa.de</span></a>
    </div>
    <div>
      betrieben.
    </div>

    <div>
      forsa wird durch die Geschäftsführer <br />
      Prof. Manfred Güllner, <br />
      Thorsten Thierhoff und <br />
      Dr. Peter Matuschek <br />
      gesetzlich vertreten.
    </div>

    <div>
      Handelsregister: <br />
      Amtsgericht Berlin-Charlottenburg <br />
      Registergericht HRB 80391
    </div>

  </div>
</template>


<script>

export default {

  name: 'ImprintContent',
}
</script>


<style scoped>
#content > * {
  margin-bottom: 24px;
}
#content > h1 {
  margin-bottom: 48px;
}
</style>